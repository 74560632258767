import React, { useState, useEffect, useRef } from 'react';

function convertToLocaleTime(timestamp) {
  // Manually parse the timestamp components
  const [datePart, timePart] = timestamp.split(' ');
  const [year, month, day] = datePart.split('-').map(Number);
  const [hours, minutes, seconds] = timePart.split(':').map(Number);

  // Construct a Date object in UTC
  const utcDate = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));

  // Format the date and time in the local time zone
  const dateString = utcDate.toLocaleDateString('en-US', {
    weekday: 'long', // "Mon"
    month: 'long', // "March"
    day: '2-digit' // "11"
  //  year: 'numeric', // "2024"
  });

  const timeString = utcDate.toLocaleTimeString('en-US', {
    hour: 'numeric', // "3"
    minute: '2-digit', // "48"
    hour12: true // Use 12-hour format
  });

  return `${timeString}, ${dateString}`; // "3:48 PM, Mon, March 11, 2024"
}

function InfiniteScroll() {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const loaderRef = useRef(null);

  const loadMoreItems = async () => {
    setLoading(true);
    const newItems = await fetchMoreItems(page);
    if (Array.isArray(newItems)) {
      setItems((prevItems) => [...prevItems, ...newItems]);
      setPage((prevPage) => prevPage + 1);
    } else {
      console.error('Expected an array but received:', newItems);
    }
    setLoading(false);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMoreItems();
        }
      },
      { threshold: 0 }
    );
    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }
    return () => observer.disconnect();
  }, [items.length]);

  return (
    <div>
        {items.map((item, index) => (
          <div class="news-item" key={index}>
            <img src={`logo_${item.Source}.png`} alt={item.Source} class="news-logo"></img>
            <div class="news-content">
               <a class="news-title" href={item.URL}>{item.Title}</a>
               <p class="news-text">{item.Summary}</p>
               <p class="news-date">{convertToLocaleTime(item.Timestamp)}</p>
            </div>
          </div>
        ))}
      <div ref={loaderRef}>{'Loading...'}</div>
    </div>
  );
}

const fetchMoreItems = async (page) => {
    try {
        const response = await fetch(`https://zx9lpst0h1.execute-api.us-east-1.amazonaws.com/Dev?page=${page}`);
        const jsonData = await response.json();
        return JSON.parse(jsonData.body); // Assuming the JSON data is an array of items
      } catch (error) {
        console.error('Error fetching data:', error);
        return [];
      }
};

export default InfiniteScroll;
